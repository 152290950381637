/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '../constants/style';

const HeroSection = styled.header`
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;

  video,
  .video-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    background: ${COLORS.darkGray};
    object-fit: cover;

    @media (max-width: 1024px) {
      object-fit: cover;
    }
  }

  .video-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0.01;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0.01;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .hide-overlay {
    display: block;
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
  }

  .info-box {
    width: 90%;
    max-width: 725px;
    max-height: calc(100vh - 100px);
    /* margin-top: 60px; */
    position: relative;
    font-size: 1.2rem;
    color: white;
    padding: 40px 60px;
    outline: solid 1px ${COLORS.brightGold};
    @media (max-width: 576px) {
      padding: 30px 15px;
      font-size: 1rem;
    }

    h1 {
      color: ${COLORS.brightGold};
      font-weight: 700;
      font-size: 2em;
      letter-spacing: 2px;
      margin-bottom: 0;
    }

    .pronunciation {
      color: ${COLORS.brightGold};
      letter-spacing: 2px;
      font-weight: 300;
      font-family: 'Crimson Text', serif;
    }

    p {
      letter-spacing: 1.5px;
      font-weight: 300;
    }

    .adjective-1 {
      margin: 0;
      font-size: 1rem;
      color: ${COLORS.textOffWhite};
    }

    .adjective-2 {
      font-family: 'Crimson Text', serif;
      font-size: 1.2rem;
      color: ${COLORS.textOffWhite};
    }

    .definition {
      margin-top: 1em;
      font-size: 1.1rem;
      color: ${COLORS.lightGray};
    }

    .course-list {
      display: inline-block;

      li {
        text-align: left;
        color: ${COLORS.textOffWhite};
        font-size: 0.8em;
        letter-spacing: 1px;
      }
    }

    .play-video-btn {
      width: 50%;
      max-width: 200px;
      cursor: pointer;
      padding: 15px 30px;
      font-size: 1rem;
      @media (max-width: 576px) {
        padding: 10px;
        font-size: 0.9rem;
      }
      background-color: transparent;
      text-decoration: none;
      border: solid 1px ${COLORS.brightGold};
      color: ${COLORS.brightGold};
      text-align: center;
      letter-spacing: 1px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;

      span {
        line-height: 1.2;
        margin-right: 0.5em;
        transition: all 0.5s;
        font-family: ${FONTS.secondary};
      }

      &:hover {
        background: ${COLORS.brightGold};
        span,
        i.fas {
          color: white;
        }
      }
    }
  }
`;

class HeroVideo extends React.Component {
  state = {
    isPlaying: false,
  };

  playVideo = () => this.setState({ isPlaying: true });

  stopVideo = () => {
    this.setState({ isPlaying: false });
  };

  componentDidMount() {
    this.video.addEventListener('pause', this.stopVideo);
  }

  render() {
    const { isPlaying } = this.state;
    return (
      <HeroSection>
        <video
          ref={vid => (this.video = vid)}
          onClick={this.stopVideo}
          controls={isPlaying}
          controlsList="nodownload"
          src="/vid/Ed_Sound.mp4"
          // src="https://s3-ap-southeast-1.amazonaws.com/liren-permission-test/WHY+EDCELLENT+(without+Logo).mp4"
          autoPlay
          playsInline
          loop
          muted={!isPlaying}
        />
        <div className={`video-overlay ${isPlaying && 'hide-overlay'}`}>
          <div
            style={{ display: isPlaying ? 'none' : 'block' }}
            className="info-box text-center"
          >
            <h1>edcellent</h1>
            <div className="pronunciation">| ɛd:s(ə)l(ə)nt |</div>
            <div className="mt-4">
              <p className="adjective-1">adjective</p>
              <p className="adjective-2">authentic, holistic, global</p>
            </div>
            <div className="mb-1">
              <div className="definition">
                <p className="m-0">The Education Hub for IB and VCE Students</p>
                <p className="m-0 mb-3">specialising in:</p>
              </div>
              <ul className="course-list">
                <li>IB English: Language and Literature </li>
                <li>VCE English & EAL</li>
                <li>VCE Literature </li>
                <li>VCE English Language </li>
                <li>Junior and Middle School English and EAL</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center">
              {this.props.ctaBtns.map(({ ctaText, ctaLink }) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  key={ctaText}
                  className="play-video-btn"
                  href={ctaLink}
                >
                  <span>{ctaText}</span>
                </a>
              ))}
              {/* <button className="play-video-btn" onClick={this.playVideo}>
                <span>Premium Sample for Students / Teachers</span>
              </button>
              <button className="play-video-btn" onClick={this.playVideo}>
                <span>Rear Window Dramatisation for School</span>
              </button> */}
            </div>
          </div>
        </div>
      </HeroSection>
    );
  }
}

export default HeroVideo;
