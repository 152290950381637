import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import HeroVideo from '../containers/HeroVideo';
import WhyChooseUs from '../containers/WhyChooseUs';
import FeaturedBlogs from '../containers/FeaturedBlogs';
import StudentSuccess from '../containers/StudentSuccess';

const indexQuery = graphql`
  {
    page: datoCmsHomePage {
      callToActionButtons {
        ctaLink
        ctaText
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    featuredPosts: allDatoCmsBlogPost(
      filter: { featured: { eq: true } }
      sort: { order: DESC, fields: meta___updatedAt }
    ) {
      edges {
        node {
          id
          title
          shortDescription
          meta {
            createdAt
            updatedAt
          }
          featuredImage {
            sizes(maxWidth: 500) {
              src
              srcSet
            }
          }
          slug
        }
      }
    }

    successStories: allDatoCmsStudentSuccess(
      filter: { featured: { eq: true } }
      sort: { order: ASC, fields: position }
    ) {
      edges {
        node {
          id
          title
          shortDescription
          meta {
            createdAt
            updatedAt
          }
          featuredImage {
            resolutions(height: 400, width: 400) {
              src
            }
          }
          slug
        }
      }
    }
  }
`;

export default function IndexPage() {
  const data = useStaticQuery(indexQuery);

  const { seoMetaTags, callToActionButtons } = data.page;
  const { edges: posts } = data.featuredPosts;
  const { edges: stories } = data.successStories;

  return (
    <>
      <SEO meta={seoMetaTags} />
      <>
        <HeroVideo ctaBtns={callToActionButtons} />
        <StudentSuccess stories={stories} />
        <WhyChooseUs />
        <FeaturedBlogs isHome posts={posts} />
      </>
    </>
  );
}
