import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../constants/style';

const UspContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
  width: 150px;

  & > img {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }

  .usp-text {
    text-align: center;
    margin: 1em 0;
    font-size: 1.15em;
    /* color: ${COLORS.brightGold}; */
  }
`;

const Usp = ({ text, iconName }) => {
  return (
    <UspContainer>
      <img src={`/img/usps/${iconName}.svg`} alt={`${text} icon`} />
      <p className="usp-text">{text}</p>
    </UspContainer>
  );
};

export default Usp;
