import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import moment from 'moment';

import { COLORS, FONTS } from '../constants/style';
import LinedTitle from '../components/LinedTitle';
import ReadMore from '../components/ReadMore';

const Section = styled(Container)`
  padding-top: 80px;
  padding-bottom: 60px;

  .video-container {
    overflow: hidden;
    position: relative;
    max-height: calc(100vh - 64px);
    width: 100%;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .success-stories {
    padding-top: 1em;
    .story {
      display: flex;
      text-decoration: none;
      margin-bottom: 1em;
      padding: 0.7em 1em;
      &:hover {
        .content {
          color: ${COLORS.darkGold};
        }
      }

      img {
        /* margin-top: 5px; */
        margin-right: 1em;
        border-radius: 2px;
        height: 120px;
        width: 120px;
        object-fit: cover;
      }

      .content {
        text-decoration: none;
        color: ${COLORS.textMain};
        margin: 0;

        .student-desc > p {
          color: ${COLORS.textGray};
          font-size: 0.8em;
          margin: 0;
          margin-bottom: 0.5em;
        }
      }
    }
  }
`;

const StudentSuccess = ({ stories }) => {
  return (
    <>
      <Section>
        <LinedTitle wing className="mb-0 mt-5 mt-md-0">
          Student Success
        </LinedTitle>
        <div className="success-stories row">
          {stories.map(
            ({
              node: { id, title, shortDescription, featuredImage, slug },
            }) => (
              <div className="col-lg-6 col-md-6" key={id}>
                <Link
                  className="story"
                  key={id}
                  to={`/student-success/${slug}`}
                >
                  <img src={featuredImage.resolutions.src} alt="id" />
                  <div className="content">
                    <h4 className="student-name">{title}</h4>
                    <div
                      className="student-desc"
                      dangerouslySetInnerHTML={{ __html: shortDescription }}
                    />
                  </div>
                </Link>
              </div>
            )
          )}
        </div>
        <ReadMore
          className="text-right mr-4"
          url={'/content#student-success'}
        />
      </Section>
      <div style={{ background: COLORS.lightGray }}>
        <Section>
          <div className="video-container">
            <iframe
              title="We do it differently in 2020"
              src="https://www.youtube.com/embed/gBhCvWdWEsY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Section>
      </div>
    </>
  );
};

export default StudentSuccess;
