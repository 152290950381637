import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { COLORS, FONTS } from '../constants/style';
import LinedTitle from '../components/LinedTitle';
import Usp from '../components/Usp';

// const UspData = [
//   { text: 'Motivation', iconName: 'motivation' },
//   { text: 'Concentration', iconName: 'concentration' },
//   { text: 'Self-esteem', iconName: 'selfesteem' },
//   { text: 'Confidence', iconName: 'confidence' },
//   { text: 'Value', iconName: 'value' },
//   { text: 'Self-worth', iconName: 'selfworth' },
//   { text: 'Identity', iconName: 'identity' },
//   { text: 'Empathy', iconName: 'empathy' },
// ];

const UspData = [
  { text: 'Confidence & Proactivity', iconName: 'motivation' },
  { text: 'Critical thinking & Analytical skills', iconName: 'concentration' },
  { text: 'Creativity & Originality', iconName: 'selfesteem' },
  { text: 'Leadership & Social influence', iconName: 'identity' },
  { text: 'Communication & Collaboration', iconName: 'empathy' },
];

const WhyChooseUsContainer = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  /* background: #494949; */
  /* background: ${COLORS.bgGray}; */

  p {
    text-align: justify;
    font-size: 1.2em;
  }

  .usp-div {
    display: flex;
    flex-wrap: wrap;
  }
`;

const WhyChooseUs = () => {
  return (
    <WhyChooseUsContainer>
      <Container>
        <LinedTitle fontSize="2.4em" wing className="mb-4">
          We Teach Differently
        </LinedTitle>
        <p>
          At Edcellent, we understand the evolving nature of the professional
          world that our students will undertake in the future. In our
          classroom, we are devoted to building the emerging soft skills that
          emphasise human capabilities:
        </p>
        <div className="py-4 flex-wrap d-flex justify-content-center align-items-center">
          {UspData.map(usp => (
            <div key={usp.text}>
              <Usp {...usp} />
            </div>
          ))}
        </div>
      </Container>
    </WhyChooseUsContainer>
  );
};

export default WhyChooseUs;
